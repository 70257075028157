<template>
  <div>
    <div v-if="!isSuccess">
      <ValidationObserver v-slot="{ handleSubmit }">
        <loading :active.sync="isSubmitted" color="#ff9300"></loading>
        <form
          @submit.prevent="handleSubmit(onSubmit)"
          class="w-full max-w-lg mx-auto pt-24 mb-16 px-3"
          style="min-height: 70vh;"
          autocomplete="off"
        >
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="mb-6">
              <h2 class="text-3xl font-semibold py-3 px-3 mb-3">
                Got a question?
              </h2>
              <p class="py-3 px-3 mb-3">
                We'd love to hear from you. Send us a message and we'll respond
                as soon as possible.
              </p>
            </div>

            <div class="w-full md:w-1/2 px-3 pt-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="firstName"
                >First name</label
              >
              <ValidationProvider
                name="first name"
                rules="required|alpha"
                mode="eager"
                v-slot="{ errors }"
              >
                <input
                  v-model="contact.firstName"
                  class="appearance-none block w-full text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                  type="text"
                  placeholder="Jane"
                />
                <p class="text-red-500 text-xs">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="w-full md:w-1/2 px-3 pt-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-last-name"
                >Last name</label
              >

              <ValidationProvider
                name="last name"
                rules="required|alpha"
                mode="eager"
                v-slot="{ errors }"
              >
                <input
                  v-model="contact.lastName"
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Smith"
                />
                <p class="text-red-500 text-xs">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div class="w-full md:w-1/2 px-3 pt-3 mb-6 md:mb-0">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-password"
                >Email</label
              >

              <ValidationProvider
                name="email"
                rules="required|email"
                mode="eager"
                v-slot="{ errors }"
              >
                <input
                  v-model="contact.email"
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="email"
                  placeholder="jane@example.com"
                />
                <p class="text-red-500 text-xs">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
            <div class="w-full md:w-1/2 px-3 pt-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-phone"
                >Phone</label
              >

              <ValidationProvider
                name="phone"
                :rules="{
                  required: true,
                  regex: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
                }"
                mode="eager"
                v-slot="{ errors }"
              >
                <input
                  v-model="contact.phone"
                  class="appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-phone"
                  type="text"
                  placeholder="0400111222"
                />
                <p class="text-red-500 text-xs">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>

            <div class="w-full px-3 pt-3">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-password"
                >Message</label
              >
              <ValidationProvider
                name="message"
                rules="required|max:1000"
                mode="eager"
                v-slot="{ errors }"
              >
                <textarea
                  v-model="contact.message"
                  class="no-resize appearance-none block w-full text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-32 resize-none"
                  maxlength="1000"
                  placeholder="We'd like to post some roles on the site."
                ></textarea>

                <p class="text-red-500 text-xs">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div>
          <div class="w-full text-right">
            <button
              class="shadow bg-orange-500 hover:bg-orange-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Contact us
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div
      v-if="isSuccess"
      class="w-full max-w-lg mx-auto pt-24 mb-16 px-3"
      style="min-height: 40vh;"
    >
      <h2 class="text-3xl font-semibold py-3 px-3 mb-3">We got your message</h2>

      <p class="py-3 px-3 mb-3">
        Thanks for reaching out to us. We'll be in touch shortly.
      </p>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import API from "@aws-amplify/api";
import { contactUs } from "../graphql/mutations";

export default {
  name: "Contact",
  components: {
    Loading,
  },
  data() {
    return {
      contact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      },
      isSubmitted: false,
      isSuccess: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isSubmitted = true;

      await API.graphql({
        query: contactUs,
        variables: { input: this.contact },
        authMode: "AWS_IAM",
      });
    },
  },
};
</script>
